<template>
    <div class="animated fadeIn">
        <b-card :title="$t('message.title.personalTaxReport')">

            <b-form>
                <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-2 col-xl-2 my-1">
                        {{ $t("message.year") }} :
                        <br />
                        <b-form-select v-model="timeSel" :options="language == 'th' ? timeOptTH : timeOptEN"
                            v-on:change="selectTime" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                        {{ $t("message.machineIdFarm") }} :
                        <br />
                        <!-- <multi-list-select :list="machineData" option-value="machineId" option-text="name"
                                                                                    :selected-items="selectedMachine" :placeholder="$t('message.all')"
                                                                                    @select="onSelectMachine"></multi-list-select> -->

                        <list-select :list="machineData" :selected-item="selectedMachine" placeholder="Select Machine ID"
                            option-value="machineId" option-text="name" @select="onSelectMachine" />
                    </div>

                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
                        {{ $t("message.refNumber") }} :
                        <br />
                        <b-form-input v-model="searchData.refNumber" type="number" />
                    </div>

                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                        {{ $t("message.selectDate") }} :
                        <br />
                        <div class="table-responsive">
                            <date-time-picker v-model="searchData.datetime"></date-time-picker>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-6 col-lg-5 col-xl-4 my-1">
                        <br />
                        <div class="row">
                            <b-btn class="col-sm-6 col-md-4 col-lg-4 col-xl-3 my-1 mr-1" variant="primary"
                                v-on:click="searchFn({ excel: false })">
                                <i class="fa fa-search"></i>
                                &nbsp;{{ $t("message.search") }}
                            </b-btn>
                            <b-btn class="col-sm-6 col-md-4 col-lg-4 col-xl-3 my-1 mr-1" v-on:click="Preview()"
                                style="background-color: #6495ED; color: #FFFF;" v-if="$isRole('owner', role_id)">
                                <i class="fa fa-file"></i>
                                &nbsp;{{ $t("message.Preview") }}
                            </b-btn>
                            <b-btn class="col-sm-6 col-md-4 col-lg-4 col-xl-3 my-1 mr-1" v-on:click="getPDF()"
                                style="background-color: #F98B88; color: #FFFF;" v-if="$isRole('owner', role_id)">
                                <i class="fa fa-file-pdf-o"></i>
                                &nbsp;{{ $t("message.PDF") }}
                            </b-btn>
                        </div>
                        <div class="row">
                            <b-btn class="col-sm-6 col-md-4 col-lg-4 col-xl-3 my-1 mr-1" v-on:click="exportBill()"
                                variant="success" v-if="$isRole('owner', role_id)">
                                <i class="fa fa-download"></i>
                                &nbsp;Export Bill
                            </b-btn>
                            &nbsp;
                            <b-btn class="col-sm-6 col-md-4 col-lg-4 col-xl-3 my-1 mr-1" v-on:click="exportList()"
                                style="background-color: forestgreen; color: #FFFF;" v-if="$isRole('owner', role_id)">
                                <i class="fa fa-download"></i>
                                &nbsp;Export List
                            </b-btn>
                        </div>

                    </div>

                </div>

                <br />
                <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-9 my-1">
                        {{ $t("message.numberOfCustomers") }} : {{ this.rowData.customer | formatNumber }}
                    </div>
                </div>
                <br />
            </b-form>
            <div class="table-responsive">
                <Table stripe row-key="transactionId" size="small" :row-class-name="rowClassName"
                    :columns="columnTransactions" :data="rowData.rows" :load-data="handleLoadData"></Table>

            </div>
            <br />
            <Page :total="this.rowData.customer" :page-size="itemperPage" @on-change="changePage" :current="currentPage" />
            <!-- <Page :total="1000" :page-size="100" @on-change="changePage" :current="5" /> -->

        </b-card>

        <!-- {{ bill }} -->
        <b-modal ref="detailModalMoney" hide-footer hide-header>
            <template v-for="(value, index) in this.bill">
                <!-- {{ value.statusLastPage }} -->
                <!-- {{ value }} -->
                <div class="col" style="text-align: center;">
                    <nav>{{ value.machinesAddress.company }}</nav>
                    <nav>{{ value.machinesAddress.addressLine1 }}</nav>
                    <nav>{{ value.machinesAddress.addressLine2 }}</nav>
                    <nav>เลขประจำตัวผู้เสียภาษี {{ value.machinesAddress.taxpayerNumber }}</nav>
                    <nav>ใบกำกับภาษีอย่างย่อ/ใบเสร็จรับเงิน</nav>
                </div>
                <div class="row">
                    <nav class="col-sm-6 col-md-6 col-lg-6 col-xl-6 my-1">วันที่ {{ value.formatDate }}</nav>
                    <nav class="col-sm-6 col-md-6 col-lg-6 col-xl-6 my-1 d-flex flex-row-reverse">
                        เบอร์โทรศัพท์ {{value.formattedPhoneNumber }}
                    </nav>
                    <!-- <nav class="col-sm-6 col-md-6 col-lg-6 col-xl-6 my-1">เบอร์ {{ value.formattedPhoneNumber }}</nav> -->
                </div>
                <div class="row">
                    <nav class="col-sm-6 col-md-6 col-lg-6 col-xl-6 my-1">เลขที่ {{ value.transByDate }}</nav>
                </div>
                <!-- length {{ value.countItems }} -->
                <table class="table-modal">
                    <thead style="text-align: center">
                        <!-- <th>{{ $t('message.productList') }}</th>
                        <th>{{ $t('message.machineSize') }}</th>
                        <th>{{ $t('message.amount') }}</th>
                        <th>{{ $t('message.money') }}</th> -->
                        <th> รายการสินค้า </th>
                        <th> ขนาดเครื่อง </th>
                        <th> จำนวน </th>
                        <th> จำนวนเงิน </th>
                    </thead>
                    <tbody>
                        <template v-for="(value, key) in value.items">
                            <tr style="font-size: 10px;">
                                <td>{{ value["product.name"] }}</td>
                                <td style="text-align: center">{{ value["device.description"] }} </td>
                                <td style="text-align: right">{{ value.qty }} </td>
                                <td style="text-align: right">{{ value.total.toFixed(2) }} </td>
                            </tr>
                        </template>
                        <tr v-for="i in 10 - value.countItems" :key="(i + 1) + value.countItems">
                            <td> <br /> </td>
                            <td> <br /> </td>
                            <td> <br /> </td>
                            <td> <br /> </td>
                        </tr>
                        <tr style="font-size: 10px;" v-if="value.statusLastPage == true">
                            <td> Refund </td>
                            <td> <br /> </td>
                            <td> <br /> </td>
                            <td style="text-align: right"> {{ value.refund.toFixed(2) }} </td>
                        </tr>
                        <tr style="font-size: 10px;" v-if="value.statusLastPage == true">
                            <td> Increase(Reduce) Advance </td>
                            <td> <br /> </td>
                            <td> <br /> </td>
                            <td style="text-align: right"> {{ value.creditBalance.toFixed(2) }} </td>
                        </tr>
                    </tbody>

                    <tfoot style="font-size: 12px;" v-if="value.statusLastPage == true">
                        <tr>
                            <th colspan="3" style="text-align: left"> จำนวนเงิน (รวมภาษีมูลค่าเพิ่ม)</th>
                            <th style="text-align: right"> {{ value.total.toFixed(2) }} </th>
                        </tr>
                    </tfoot>

                    <tfoot style="font-size: 12px;" v-else>
                        <tr></tr>
                        <tr></tr>
                    </tfoot>
                </table>
                <div class="row my-1">
                    <nav class="col-sm-6 col-md-6 col-lg-6 col-xl-6 my-1">ผู้รับเงิน บริษัท Wonder Wash</nav>
                </div>
                <div class="row my-1">
                    <nav class="col-sm-6 col-md-6 col-lg-6 col-xl-6 my-1"></nav>
                    <nav class="col-sm-6 col-md-6 col-lg-6 col-xl-6 my-1 d-flex flex-row-reverse">ใบที่ {{ value.page }}
                    </nav>
                </div>
                <hr /><br />
            </template>
        </b-modal>
    </div>
</template>
<script>
import Vue from 'vue'
import webServices from '../../script'
// import DateTimePicker from '../../components/DateTimePicker/Custom.vue'
import DateTimePicker from "../../components/DateTimePicker/DatePicker.vue";


import FileSaver from 'file-saver'
import { ListSelect, MultiSelect, MultiListSelect } from '../../custom_modules/search-select'
import moment from 'moment'
import VueJsonPretty from 'vue-json-pretty'
import { json } from 'body-parser';

//https://stackoverflow.com/questions/44538110/how-to-format-numbers-in-vuejs
var numeral = require('numeral')

Vue.filter('formatNumber', function (value) {
    return numeral(value).format('0,0') // displaying other groupings/separators is possible, look at the docs
})

export default {
    name: 'orders',
    components: {
        // DatePicker,
        DateTimePicker,
        MultiSelect,
        MultiListSelect,
        ListSelect,
    },
    data() {
        return {
            api: {},
            timeSel: 0,
            timeOptTH: [
                { value: 0, text: '2022 ถึง ปีปัจจุบัน' },
                { value: 1, text: '<2022' }
            ],
            timeOptEN: [
                { value: 0, text: '2022 To This Year' },
                { value: 1, text: '<2022' }
            ],
            language: window.localStorage.getItem("language"),
            role_id: window.localStorage.getItem('roleID'),
            userBusiness: window.localStorage.getItem('business'),
            rowData: {},
            totalRows: 0,
            totalSubRows: 0,
            currentPage: 1,
            itemperPage: 50,
            searchData_export: {},
            searchData: {
                datetime: [moment().startOf('day').toDate(), moment().endOf('day').toDate()],
                id: '',
                machineId: '',
                deviceId: '',
                productId: '',
                qty: '',
                price: '',
                acceptorMoney: '',
                changerMoney: '',
                status: '',
                paymentType: '',
                refNumber: '',
                type: 'washing'
            },
            bill: [],
            branch: '',
            fromdate: '',
            todate: '',
            startDate: '',
            endDate: '',
            ProductItems: [],
            selectedProduct: [],
            title: [],
            summary: {},
            machineData: [],
            selectedMachine: [],
            columnTransactions: [
                // {
                //     title: 'summary',
                //     key: 'summary',
                //     minWidth: 60,
                //     align: 'center',
                //     tree: true
                // },
                {
                    title: this.$t("message.date"),
                    minWidth: 80,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "span",
                            params.row.date,
                            // moment(params.row.createdAt).format("YYYY-MM-DD HH:mm:ss")
                        );
                    },
                },
                {
                    title: this.$t('message.machineId'),
                    key: 'machineId',
                    minWidth: 60,
                    align: 'center',
                    tree: true
                },
                {
                    title: this.$t("message.machineName"),
                    minWidth: 114,
                    align: "left",
                    ellipsis: true,
                    // tooltip: true,
                    render: (h, params) => {
                        // return h('span', params.row.machine.name)
                        return h(
                            "Poptip",
                            {
                                props: {
                                    trigger: "hover",
                                    content: params.row['machine.name'],
                                },
                            },
                            [h("span", params.row['machine.name'])]
                        );
                    },
                },
                {
                    title: this.$t("message.refNumber"),
                    key: "refNumber",
                    minWidth: 110,
                    align: "center",
                },
                {
                    title: this.$t("message.amountItems"),
                    minWidth: 76,
                    align: "center",
                    key: 'qty',
                    // render:  params.row.qty,
                },
                {
                    title: this.$t("message.money"),
                    minWidth: 76,
                    align: "center",
                    key: 'summary',
                    // render:  params.row.total,
                },
                {
                    title: this.$t("message.Preview"),
                    // slot: 'action',
                    width: 90,
                    align: "center",
                    render: this.renderDetail,
                },
                {
                    title: this.$t("message.PDF"),
                    // slot: 'action',
                    width: 90,
                    align: "center",
                    render: this.renderPDF,
                },
                {
                    title: 'Bill',
                    // slot: 'action',
                    width: 90,
                    align: "center",
                    render: this.renderEXCEL,
                },
            ],

        }
    },
    async beforeMount() {
        this.setParams(this.$route.query)
        await this.getProduct()
        await this.getMachine()
        this.selectedProduct = this.ProductItems.filter(data => data.id == this.searchData.productId)
        this.selectedMachine = this.machineData.filter(data => data.machineId === this.searchData.machineId)
        if (!this.$isRole('admin', this.role_id) && !this.$isRole('callcenter', this.role_id)) {
            await this.getTransactions()
        }

        if (this.$route.query.machineId) {
            console.log('data ', this.$route.query)
            this.searchFn()
        }
    },
    methods: {
        selectTime(value) {
            if (value > 0) {
                this.api.defaults.baseURL = '/old/' + this.axios.defaults.baseURL
            } else {
                this.api.defaults.baseURL = this.axios.defaults.baseURL
            }
        },

        setParams(query) {
            if (Object.keys(query).length != 0) {
                // console.log(query)
                this.fromdate = query.from
                this.todate = query.to
                this.searchData.datetime = [moment(query.from).toDate(), moment(query.to).toDate()]
                this.searchData.productId = query.productId || ''
                this.searchData.machineId = query.machineId || ''
            }
        },
        rowClassName() {
            return 'demo-table-info-row'
        },

        showPaymentStatus(h, params) {
            if (params.row.paymentDetails != null && params.row.paymentDetails.coupon) {
                params.row.paymentType = 'COUPON'
            }

            return this.paymentStatus(h, params.row.paymentType, params.row.status_paymentType)
        },

        renderPDF(h, params) {
            return h('Button', {
                props: {
                    size: 'small',
                },
                style: {
                    width: '50px',
                    // marginRight: '5px',
                    backgroundColor: '#F98B88',
                    color: '#FFFF',
                    fontSize: '12px'
                },
                on: {
                    click: () => {
                        this.getPDF_byRow(params.row)
                    }
                }
            }, 'PDF')
        },

        renderEXCEL(h, params) {
            return h('Button', {
                props: {
                    type: 'success',
                    size: 'small',
                },
                style: {
                    width: '50px',
                    // marginRight: '5px',
                    fontSize: '12px'
                },
                on: {
                    click: () => {
                        this.getEXCEL_byRow(params.row)
                    }
                }
            }, 'Excel')
        },

        renderDetail(h, params) {
            return h('Button', {
                props: {
                    type: 'primary',
                    size: 'small',
                    icon: 'ios-expand'
                },
                style: {
                    width: '50px',
                },
                on: {
                    click: () => {
                        // this.showDetails(params.row)
                        this.showModal(params.row)
                    }
                }
            })
        },

        async getPDF_byRow(data) {
            this.$Progress.start()
            this.searchData = this.searchData_export
            this.fromdate = this.searchData_export.from
            this.todate = this.searchData_export.to

            const params = {
                ...this.searchData_export,
                ...data,
                options: 'queryByRow',
                from: this.fromdate,
                to: this.todate,
                type: ["washing",], // ["washing", "addCredit"]
            }

            this.axios({
                url: `/financial/personaltaxreport/download/pdf`, // File URL Goes Here
                method: 'get',
                params,
                responseType: 'blob'
            }).then(response => {
                // console.log('response', response.data)
                if (this.searchData.machineId.length > 0) {
                    this.branch = this.searchData.machineId
                } else {
                    this.branch = '...'
                }
                console.log('response.data', response.data)
                // FileSaver.saveAs(response.data)
                FileSaver.saveAs(response.data, `Report_Personal_Tax_PDF_${this.branch}_date_${moment(this.fromdate).format('YYYY-MM-DD')}_to_${moment(this.todate).format('YYYY-MM-DD')}.pdf`)
                this.$Progress.finish()
            })
        },

        async getPDF() {
            this.$Progress.start()
            const params = {
                from: this.fromdate,
                to: this.todate,
                machineId: this.searchData.machineId,
                name: this.searchData.name,
                userName: this.searchData.userName,
                userRole: this.searchData.userRole,
                userType: this.searchData.userType,
                userConfiglanguage: this.searchData.userConfiglanguage,
                datetimeRequest: this.searchData.datetimeRequest,
            }

            // console.log('params', params)
            this.axios({
                url: `/financial/personaltaxreport/download/pdf`, // File URL Goes Here
                method: 'get',
                params,
                responseType: 'blob'
            }).then(response => {
                if (this.searchData.machineId.length > 0) {
                    this.branch = this.searchData.machineId
                } else {
                    this.branch = '...'
                }
                // console.log('response.data', response.data)
                FileSaver.saveAs(response.data, `Report_Personal_Tax_PDF_${this.branch}_date_${moment(this.fromdate).format('YYYY-MM-DD')}_to_${moment(this.todate).format('YYYY-MM-DD')}.pdf`)
                this.$Progress.finish()
            })
        },

        async getEXCEL_byRow(data) {
            this.$Progress.start()

            this.searchData = this.searchData_export
            this.fromdate = this.searchData_export.from
            this.todate = this.searchData_export.to

            const params = {
                ...this.searchData_export,
                ...data,
                options: 'queryByRow',
                from: this.fromdate,
                to: this.todate,
                type: ["washing",], // ["washing", "addCredit"]
            }

            // console.log('params', params)
            this.axios({
                url: `/financial/personaltaxreport/download/bill`, // File URL Goes Here
                method: 'GET',
                params,
                responseType: 'blob'
            }).then(response => {
                if (this.searchData.machineId.length > 0) {
                    this.branch = this.searchData.machineId
                } else {
                    this.branch = '...'
                }
                FileSaver.saveAs(response.data, `Report_Personal_Tax_${this.branch}_date_${moment(this.fromdate).format('YYYY-MM-DD')}_to_${moment(this.todate).format('YYYY-MM-DD')}.xlsx`)
                this.$Progress.finish()
            })
        },

        async exportList() {
            this.$Progress.start()

            console.log('exportList', this.searchData)
            const params = {
                from: this.fromdate,
                to: this.todate,
                machineId: this.searchData.machineId,
                name: this.searchData.name,
                userName: this.searchData.userName,
                userRole: this.searchData.userRole,
                userType: this.searchData.userType,
                userConfiglanguage: this.searchData.userConfiglanguage,
                datetimeRequest: this.searchData.datetimeRequest,
            }

            // console.log('params', params)
            this.axios({
                url: `/financial/personaltaxreport/download/list`, // File URL Goes Here
                method: 'GET',
                params,
                responseType: 'blob'
            }).then(response => {
                if (this.searchData.machineId.length > 0) {
                    this.branch = this.searchData.machineId
                } else {
                    this.branch = '...'
                }
                FileSaver.saveAs(response.data, `Report_Personal_list_Tax_${this.branch}_date_${moment(this.fromdate).format('YYYY-MM-DD')}_to_${moment(this.todate).format('YYYY-MM-DD')}.xlsx`)
                this.$Progress.finish()
            })
        },

        async exportBill() {
            this.$Progress.start()

            // console.log('exportBill', this.searchData)
            const params = {
                from: this.fromdate,
                to: this.todate,
                machineId: this.searchData.machineId,
                name: this.searchData.name,
                userName: this.searchData.userName,
                userRole: this.searchData.userRole,
                userType: this.searchData.userType,
                userConfiglanguage: this.searchData.userConfiglanguage,
                datetimeRequest: this.searchData.datetimeRequest,
            }

            // console.log('params', params)
            this.axios({
                url: `/financial/personaltaxreport/download/bill`, // File URL Goes Here
                method: 'GET',
                params,
                responseType: 'blob'
            }).then(response => {
                if (this.searchData.machineId.length > 0) {
                    this.branch = this.searchData.machineId
                } else {
                    this.branch = '...'
                }
                FileSaver.saveAs(response.data, `Report_Personal_Tax_${this.branch}_date_${moment(this.fromdate).format('YYYY-MM-DD')}_to_${moment(this.todate).format('YYYY-MM-DD')}.xlsx`)
                this.$Progress.finish()
            })
        },

        async showModal(data, items) {
            this.$Progress.start()
            console.log('showModal')
            // console.log('data', data)

            data.from = this.fromdate
            data.to = this.todate
            data.options = 'queryByRow'
            delete data.paymentType

            const params = {
                ...this.searchData,
                ...data
            }

            await this.axios({
                url: `/financial/personaltaxreport/bill`,
                method: 'GET',
                params,
                // responseType: 'blob'
            }).then(response => {
                this.bill = response.data.bill
                this.$Progress.finish()
            })
            // console.log('bill', this.bill)
            this.$refs.detailModalMoney.show();
        },

        async Preview(page = 1) {
            this.$Progress.start()
            console.log('Preview')
            console.log('this.searchData', this.searchData)

            const rows = this.itemperPage
            const params = {
                ...this.searchData,
                page: page,
                rows: rows,
                from: this.fromdate,
                to: this.todate,
                type: ["washing",], // ["washing", "addCredit"]
            }

            await this.axios({
                url: `/financial/personaltaxreport/bill`,
                method: 'GET',
                params,
                // responseType: 'blob'
            }).then(response => {
                this.bill = response.data.bill
                this.$Progress.finish()
            })

            console.log('this.bill', this.bill)
            this.$refs.detailModalMoney.show();
        },

        getExcel(data) {
            this.$Progress.start()
            data.from = this.fromdate
            data.to = this.todate
            data.options = 'queryByRow'
            delete data.paymentType

            // const params = data
            const params = {
                ...this.searchData,
                ...data
            }
            console.log('params', params)
            console.log('getExcel', data)

            this.axios({
                url: `/financial/personaltaxreport/download/bill`, // File URL Goes Here
                method: 'GET',
                params,
                responseType: 'blob'
            }).then(response => {
                if (this.searchData.machineId.length > 0) {
                    this.branch = this.searchData.machineId
                } else {
                    this.branch = '...'
                }
                FileSaver.saveAs(response.data, `Report_Personal_Tax_${this.branch}_date_${moment(this.fromdate).format('YYYY-MM-DD')}_to_${moment(this.todate).format('YYYY-MM-DD')}.xlsx`)
                this.$Progress.finish()
            })
        },

        async getTransactions(page = 1) {
            this.$Progress.start()

            this.currentPage = page

            const rows = this.itemperPage
            const params = {
                ...this.searchData,
                page: page,
                rows: rows,
                from: this.fromdate,
                to: this.todate,
                type: ["washing",], // ["washing", "addCredit"]
            }

            // console.log('params', params)
            if (Array.isArray(params.machineId)) {
                params.machineId = this.searchData.machineId.join(',')
            }

            if (Array.isArray(params.productId)) {
                params.productId = this.searchData.productId.join(',')
            }

            delete params.id
            delete params.datetime

            await this.axios
                .get(`/financial/personaltaxreport/`, { params })
                .then(res => {
                    this.$Progress.finish()
                    this.rowData = res.data

                    this.searchData_export = params

                    this.rowData.rows.forEach((row, idx) => {
                        if (row.transactionType == 'MAIN') {
                            this.rowData.rows[idx].children = []
                            this.rowData.rows[idx]._loading = false
                        }
                    })

                    // console.log('this.rowData', this.rowData)
                    this.totalRows = res.data.count
                    this.totalSubRows = res.data.subCount
                })
                .catch(err => {
                    this.$Progress.fail()
                    console.log('error Orders')
                    console.log(err)
                    this.$toast.error({
                        title: 'ERROR',
                        message: 'เกิดข้อผิดพลาด'
                    })
                    if (err.response.status === 401) {
                        webServices.tokenExpire()
                    }
                })

        },

        handleLoadData(item, callback) {
            const params = {
                transactionId: item.details.subTransaction.join()
            }

            this.axios
                .get(`/subtransactions`, { params })
                .then(res => {
                    this.$Progress.finish()
                    callback(res.data.rows)
                })
                .catch(err => {
                    this.$Progress.fail()
                    console.log(err)
                    this.$toast.error({
                        title: 'ERROR123',
                        message: err
                    })
                    if (err.response.status === 401) {
                        webServices.tokenExpire()
                    }
                })
        },
        async getMachine() {
            this.$Progress.start()
            await this.axios
                .get(`/machines/list`)
                .then(res => {
                    this.$Progress.finish()
                    this.machineData = res.data
                    if (this.machineData.length == 1) {
                        this.selectedMachine = this.machineData
                    }
                })
                .catch(err => {
                    this.$Progress.fail()
                    this.$toast.error({
                        title: 'ERROR',
                        message: {
                            error: err.response.data,
                            code: err.response.status,
                            text: err.response.statusText
                        }
                    })
                    console.log('error @machine')
                    console.log(err)
                    if (err.response.status === 401) {
                        webServices.tokenExpire()
                    }
                })
        },
        isAdmin(role) {
            return webServices.isAdmin(role)
        },
        isDealer(role) {
            return webServices.isDealer(role)
        },
        changePage(PageNum) {
            // window.scrollTo(0, 0)
            this.getTransactions(PageNum)
        },

        showAlert_ChooseOneBranch() {
            if (window.localStorage.getItem("language") == 'th') {
                var title = "โปรดเลือกช่วงเวลาใหม่อีกครั้ง"
                var text = 'ไม่สามารถเลือกเดือนได้มากกว่า 12 เดือน'
                var confirmButton = "ปิด"
            } else {
                var title = "Please select a new time again"
                var text = "Cannot select more than 12 months."
                var confirmButton = "Close"
            }

            this.$swal({
                title: title,
                text: text,
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonColor: '#3085d6',
                // cancelButtonColor: '#d33',
                confirmButtonText: confirmButton
            })
        },
        showAlert_noneSelectBranch(){
            if (window.localStorage.getItem("language") == 'th') {
                var title = "กรุณาเลือกสาขา"
                var confirmButton = "ปิด"
            } else {
                var title = "Please select a branch"
                var confirmButton = "Close"
            }

            this.$swal({
                title: title,
                // html: '<pre>' + text + '</pre>',
                // text: text ,
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonColor: '#3085d6',
                // cancelButtonColor: '#d33',
                confirmButtonText: confirmButton
            })
        },

        showAlert_ChooseThanOneBranch() {
            if (window.localStorage.getItem("language") == 'th') {
                var title = "โปรดเลือกช่วงเวลาใหม่อีกครั้ง"
                var text = "เลือกมากกว่า 1 สาขา \n " + "ไม่สามารถเลือกเดือนได้มากกว่า 2 เดือน\n"
                var confirmButton = "ปิด"
            } else {
                var title = "Please select a new time again"
                var text = "Choose than 1 branch, \n " + "Cannot select more than 2 months.\n"
                var confirmButton = "Close"
            }

            this.$swal({
                title: title,
                html: '<pre>' + text + '</pre>',
                // text: text ,
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonColor: '#3085d6',
                // cancelButtonColor: '#d33',
                confirmButtonText: confirmButton
            })
        },

        monthDiff(d1, d2) {
            var months;
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth();
            months += d2.getMonth();
            return months <= 0 ? 0 : months;
        },

        searchFn(opt = { excel: false }) {
            if (this.searchData.datetime !== '') {
                this.fromdate = moment(this.searchData.datetime[0]).format('YYYY-MM-DD HH:mm:ss')
                // this.todate = moment(this.searchData.datetime[1]).format('YYYY-MM-DD HH:mm:ss')
                this.todate = moment(this.searchData.datetime[1]).endOf('date').format("YYYY-MM-DD HH:mm:ss");
                // console.log(this.todate)
            } else {
                this.fromdate = ''
                this.todate = ''
            }

            if (this.searchData.type != 'washing') {
                this.transactionType = 'ALL'
            }
            var localStorageUser = JSON.parse(window.localStorage.getItem("users"))
            var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

            this.searchData.userName = localStorageUser.userName
            this.searchData.userRole = localStorageUser.role
            this.searchData.userType = localStorageUser.type
            this.searchData.userConfiglanguage = localStorageUser.config.language
            this.searchData.datetimeRequest = datetimeRequest

            this.searchData.machineSelected = 1

            var dateStart = new Date(this.searchData.datetime[0])
            var dateEnd = new Date(this.searchData.datetime[1])

            // if (this.searchData.machineId == ''|| this.searchData.machineId == undefined ) {
            //     this.showAlert_noneSelectBranch()
            // }

            // case เลือก 1 สาขา จะเลือกช่วงเวลาได้มากสุด 1 ปี
            if (this.searchData.machineSelected == 1 || window.localStorage.getItem("roleID") == 'dealer') {
                console.log("เลือก 1 สาขา หรือ dealer")
                if (this.monthDiff(dateStart, dateEnd) < 12) {
                    if (!opt.excel) {
                        this.getTransactions(1)
                        this.getProduct()
                    } else {
                        if (this.rowData.rows.length > 0) {
                            this.exportBill()
                        }
                    }

                } else {
                    console.log("เลือกเดือนได้สูงสุด 12 เดือน")
                    this.showAlert_ChooseOneBranch() // เลือกเดือนได้สูงสุด 12 เดือน
                }
                // case เลือกมากกว่า 1 สาขา จะเลือกช่วงเวลาได้มากสุด 2 เดือน
            } else {
                console.log("เลือกมากกว่า 1 สาขา")
                if (this.monthDiff(dateStart, dateEnd) < 2) {
                    if (!opt.excel) {
                        this.getTransactions(1)
                        this.getProduct()
                    } else {
                        if (this.rowData.rows.length > 0) {
                            this.exportBill()
                        }
                    }

                } else {
                    console.log("เลือกเดือนได้สูงสุด 2 เดือน")
                    this.showAlert_ChooseThanOneBranch() // เลือกเดือนได้สูงสุด 2 เดือน
                }
            }

        },

        getPrice(data) {
            switch (data.status) {
                case 'PROCESS':
                case 'SUCCESS':
                    return data.price
                default:
                    return data.details.selectedPrices ? data.details.selectedPrices : data.price
            }
        },
        onSelectMachine(items) {
            // console.log('onSelectMachine', items)
            this.selectedMachine = items
            this.searchData.machineId = items.machineId
            this.searchData.name = items.name.substr(9).replaceAll('\\r\\n', '')

            // this.searchData.machineId = this.selectedMachine.map(data => data.machineId)
            // this.searchData.name = this.selectedMachine.map((data) => data.name.substr(9).replaceAll('\\r\\n', ''))
        },
        onSelect(items) {
            // console.log(items)
            this.selectedProduct = items
            this.searchData.productId = this.selectedProduct.map(data => data.id)
        },
        async getProduct() {
            const params = { type: this.searchData.type }
            await this.axios
                .get(`/products/list`, { params })
                .then(res => {
                    this.ProductItems = res.data
                })
                .catch(err => {
                    const errorText = {
                        error: err.response.data,
                        code: err.response.status,
                        text: err.response.statusText
                    }
                    this.$toast.error({
                        title: 'ERROR',
                        message: JSON.stringify(errorText)
                    })
                    console.log('error @get product ORDER')
                    console.log(err)
                })
        },

    }
}
</script>

<style>
@import './../style.css';

.spanCustom {
    cursor: pointer;
    color: #20a8d8;
    border-color: #20a8d8;
    text-decoration: underline;
}

.spanCustom:hover {
    text-decoration: none;
    text-shadow: 1px 1px 1px #555;
}

.ivu-table-cell {
    font-size: 0.9em;
    padding-left: 10px;
    padding-right: 0px;
}

.ivu-table-small td {
    height: 30px;
}

.ivu-tag {
    font-size: 0.9em;
    margin: 0 2px 0 0;
    padding: 0 4px;
}
</style>
